import React, { useState, useEffect } from "react";
import Typical from "react-typical";
import Switch from "react-switch";

function Header(props) {
  const [title, setTitle] = useState([]);
  const [checked, setChecked] = useState(false);
  const [name, setName] = useState();

  const onThemeSwitchChange = (checked) => {
    setChecked(checked);
    setTheme();
  };

  const setTheme = () => {
    var dataThemeAttribute = "data-theme";
    var body = document.body;
    var newTheme =
      body.getAttribute(dataThemeAttribute) === "dark" ? "light" : "dark";
    body.setAttribute(dataThemeAttribute, newTheme);
  };

  useEffect(() => {
    const fetchData = async () => {
      if (props.sharedData) {
        setName(props.sharedData.name);
        setTitle(
          props.sharedData.titles.map((x) => [x.toUpperCase(), 1500]).flat()
        );
      }
      if (!props.sharedData) {
        return null;
      }
    };
    fetchData();
  }, [props.sharedData]);

  const HeaderTitleTypeAnimation = React.memo(
    () => {
      return <Typical className="title-styles" steps={title} loop={30} />;
    },
    (props, prevProp) => true
  );

  return (
    <header
      id="home"
      style={{ height: window.innerHeight - 140, display: "block" }}
    >
      <div className="row aligner" style={{ height: "100%" }}>
        <div className="col-md-12">
          <div>
            <span
              className="iconify header-icon"
              data-icon="la:laptop-code"
              data-inline="false"
              style={{ color: "white" }}
            ></span>
            {/* <img src="./images/logo.png" alt="logo" className="logo" /> */}
            <br />
            <h1 className="mb-0 title-styles-persian">
              <Typical steps={[name]} wrapper="p" />
            </h1>
            <div className="title-container">
              <HeaderTitleTypeAnimation />
            </div>
            <Switch
              checked={checked}
              onChange={onThemeSwitchChange}
              offColor="#baaa80"
              onColor="#353535"
              className="react-switch mx-auto"
              width={90}
              height={40}
              uncheckedIcon={
                <span
                  className="iconify"
                  data-icon="emojione:crescent-moon"
                  data-inline="false"
                  style={{
                    display: "block",
                    height: "100%",
                    fontSize: 25,
                    textAlign: "end",
                    marginLeft: "20px",
                    color: "#353239",
                  }}
                ></span>
              }
              checkedIcon={
                <span
                  className="iconify"
                  data-icon="emojione:sun"
                  data-inline="false"
                  style={{
                    display: "block",
                    height: "100%",
                    fontSize: 25,
                    textAlign: "end",
                    marginLeft: "10px",
                    color: "#353239",
                  }}
                ></span>
              }
              id="icon-switch"
            />
          </div>
        </div>
      </div>
    </header>
  );
}

export default Header;
