import React, { useState, useEffect } from "react";
import {
  VerticalTimeline,
  VerticalTimelineElement,
} from "react-vertical-timeline-component";
import "react-vertical-timeline-component/style.min.css";
import Badge from "react-bootstrap/Badge";

function Experience(props) {
  const [sectionName, setSectionName] = useState();
  const [work, setWork] = useState();

  useEffect(() => {
    const fetchData = async () => {
      if (props.resumeExperience && props.resumeBasicInfo) {
        setSectionName(props.resumeBasicInfo.section_name.experience);
        setWork(
          props.resumeExperience.map(function (work, i) {
            const technologies = work.technologies;
            const mainTechnologies = work.mainTech;

            var mainTech = mainTechnologies.map((technology, i) => {
              return (
                <Badge
                  pill
                  className={`main-badge mr-2 mb-2 ${technology}`}
                  key={i}
                >
                  {" "}
                </Badge>
              );
            });
            var tech = technologies.map((technology, i) => {
              return (
                <Badge pill className="experience-badge mr-2 mb-2" key={i}>
                  {technology}
                </Badge>
              );
            });
            return (
              <VerticalTimelineElement
                className="vertical-timeline-element--work"
                date={work.years}
                iconStyle={{
                  // background: "#AE944F",
                  background: "#3374CA",
                  color: "#fff",
                  textAlign: "center",
                  display: "grid",
                }}
                icon={<i className={work.mainTech + " experience-icon"}></i>}
                key={i}
              >
                <div style={{ textAlign: "left", marginBottom: "4px" }}>
                  {mainTech}
                </div>

                <h3
                  className="vertical-timeline-element-title"
                  style={{ textAlign: "left" }}
                >
                  {work.title}
                </h3>
                <h4
                  className="vertical-timeline-element-subtitle"
                  style={{ textAlign: "left" }}
                >
                  {work.company}
                </h4>
                <div style={{ textAlign: "left", marginTop: "15px" }}>
                  {tech}
                </div>
              </VerticalTimelineElement>
            );
          })
        );
      }
      if (!props.resumeExperience && !props.resumeBasicInfo) {
        return null;
      }
    };
    fetchData();
  }, [props.resumeExperience, props.resumeBasicInfo]);

  return (
    <section id="resume" className="pb-5">
      <div className="col-md-12 mx-auto">
        <div className="col-md-12">
          <h1 className="section-title" style={{ color: "black" }}>
            <span
              className="text-black"
              style={{
                textAlign: "center",
                fontSize: "200%",
                fontFamily: "BtitrBold",
              }}
            >
              {sectionName}
            </span>
          </h1>
        </div>
      </div>
      <div className="col-md-8 mx-auto">
        <VerticalTimeline>
          {work}
          <VerticalTimelineElement
            iconStyle={{
              // background: "#AE944F",
              background: "#3374CA",
              color: "#fff",
              textAlign: "center",
            }}
            icon={
              <i className="fas fa-hourglass-start mx-auto experience-icon"></i>
            }
          />
        </VerticalTimeline>
      </div>
    </section>
  );
}

export default Experience;
